<template>
  <button class="hamburger" ref="hamburger" aria-label="Open Menu">
    <span class="line" data-animate="hamburger-line"></span>
    <span class="line" data-animate="hamburger-line"></span>
    <span class="line" data-animate="hamburger-line"></span>
  </button>
</template>

<script>
export default {
  name: 'WidgetHamburger',
}
</script>

<script setup>
import { gsap } from 'gsap'

const emit = defineEmits([
  'animation-in-start',
  'animation-start',
  'animation-out-start',
])

const props = defineProps({
  active: Boolean,
})

const hamburger = ref(null)

const tl = ref(null)

watch(
  () => props.active,
  (active) => {
    if (!tl.value?.isActive()) {
      tl.value = active ? animateIn() : animateOut()
      tl.value.play()
    }
  },
)

// Animazioni
const animateIn = (params) => {
  const parent = hamburger.value
  const lines = parent.querySelectorAll('[data-animate="hamburger-line"]')

  const scaleValue = 0.8

  const tl = gsap.timeline({
    paused: true,
    onStart: () => {
      emit('animation-in-start')
    },
    ...params,
  })

  tl.to(
    hamburger.value,
    {
      scale: scaleValue,
    },
    'start',
  )
    .to(lines[0], { y: '10px', rotation: 45, ease: 'power1.out' }, 'start')
    .to(lines[1], { scaleX: 0, ease: 'power1.out' }, 'start')
    .to(lines[2], { y: '-10px', rotation: -45, ease: 'power1.out' }, 'start')

  return tl
}

const animateOut = (params) => {
  const parent = hamburger.value
  const lines = parent.querySelectorAll('[data-animate="hamburger-line"]')

  const tl = gsap.timeline({
    paused: true,
    onStart: () => {
      emit('animation-out-start')
    },
    ...params,
  })

  tl.to(
    hamburger.value,
    {
      scale: 1,
    },
    'start',
  )
    .to(lines[0], { y: '0px', rotation: 0, ease: 'power1.out' }, 'start')
    .to(lines[1], { scaleX: 1, ease: 'power1.out' }, 'start')
    .to(lines[2], { y: '0px', rotation: 0, ease: 'power1.out' }, 'start')
  return tl
}

defineExpose({
  animateIn,
  animateOut,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
